import Bb8Icon from '@/components/icons/Bb8Icon';
import LeftArrowIcon from '@/components/icons/LeftArrowIcon';
import StarWarsIcon from '@/components/icons/StarWarsIcon';
import Button from '@/components/ui/Button/Button';
import Gradient from '@/components/ui/Effect/Gradient';
import classes from './HttpError.module.css';

type Props = {
  children: React.ReactNode;
  code: number;
};

function HttpError(props: Props) {
  const onClickFunction = () => {
    window.history.go(-1);
    return false;
  };

  let background = '500_background.png';
  let containerClass = classes.container500;
  let icon = <StarWarsIcon />;

  if (props.code === 404) {
    background = '404_background.png';
    containerClass = classes.container404;
    icon = <Bb8Icon />;
  }

  return (
    <Gradient backgroundImage={`/assets/${background}`} gradientClass="error">
      <div className={containerClass}>
        <div className={classes.box}>
          {icon}
          <h1>{props.code}</h1>
          {props.children}
          <div className={classes.buttons}>
            <Button href="#" alternate={true} onClickHandler={onClickFunction}>
              <LeftArrowIcon />
              Retour
            </Button>
            <Button href="/" alternate={true}>
              <StarWarsIcon />
              Page d&apos;accueil
            </Button>
          </div>
        </div>
      </div>
    </Gradient>
  );
}

export default HttpError;
