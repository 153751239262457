import HttpError from '@/components/ui/Block/Navigation/HttpError';
import Layout from '@/components/ui/Layout/Layout';

function Page404() {
  return (
    <Layout useTransparentHeader={true}>
      <HttpError code={404}>
        <p>
          <em>Ce ne sont pas ces droïdes-là que vous recherchez.</em>
        </p>
        <p>La page demandée est introuvable.</p>
      </HttpError>
    </Layout>
  );
}

export default Page404;
